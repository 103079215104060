import React from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import { FamilyInterface } from "../../../interfaces/UserInterface";
import PieChartContainer from "../../../utils/PieChartContainer";

interface Props {
	families: FamilyInterface[];
	seniorLength: number;
	userLocale: string;
}

const FamilyStatistics = ({ families, seniorLength, userLocale }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const principals = families.filter(family => family.principal);
	const affiliates = families.filter(family => !family.principal);
	const averageAffiliatePerPrincipal = affiliates.length / principals.length;
	const averageFamiliesPerSenior = families.length / seniorLength;

	const today = new Date();
	const currentYear = today.getFullYear();
	const currentMonth = today.getMonth();

	const familiesCreatedThisMonth = families.filter((family: FamilyInterface) => {
		const creationMonth = family.inserted_at.split("-")[1];
		const creationYear = family.inserted_at.split("-")[0];
		return parseInt(creationMonth) === currentMonth && parseInt(creationYear) === currentYear ? family : null;
	});

	const familiesCreatedThisYear = families.filter((family: FamilyInterface) => {
		const creationYear = family.inserted_at.split("-")[0];
		return parseInt(creationYear) === currentYear ? family : null;
	});

	const data = [
		{
			name: t("family.__principals__"),
			value: Math.floor((principals.length / families.length) * 100)
		},
		{
			name: t("family.__affiliates__"),
			value: Math.floor((affiliates.length / families.length) * 100)
		}
	];

	const filterLastFamiliesConnected = (families: FamilyInterface[]): FamilyInterface[] => {
		const filteredFamilies = families.filter((family: FamilyInterface) => family.last_sign_in_at);
		const familiesOrderedByLastSignIn =
			filteredFamilies.length > 0
				? filteredFamilies.sort((a: any, b: any) => a.last_sign_in_at.localeCompare(b.last_sign_in_at.split(" ").join("T")))
				: [];
		return familiesOrderedByLastSignIn.length > 0
			? familiesOrderedByLastSignIn.slice(familiesOrderedByLastSignIn.length - 5, familiesOrderedByLastSignIn.length - 1)
			: [];
	};
	const fourLastFamiliesConnected = families.length > 0 ? filterLastFamiliesConnected(families) : null;
	const lastFamiliesConnected = fourLastFamiliesConnected ? fourLastFamiliesConnected.reverse() : null;

	const filterActiveFamilies = (families: FamilyInterface[]): FamilyInterface[] => {
		const filteredFamilies = families.filter((family: FamilyInterface) => family.sign_in_count > 0);
		const activeMembers =
			filteredFamilies.length > 0
				? filteredFamilies.sort((a: any, b: any) => (a.sign_in_count > b.sign_in_count ? -1 : 1))
				: [];
		return activeMembers.slice(0, 4);
	};
	const activeMembers = families.length > 0 ? filterActiveFamilies(families) : null;

	return (
		<div className="row mt-2">
			<div className="col-md-3">
				<div className="gradient-card blue">
					<h5 className="gradient-title">{averageFamiliesPerSenior.toFixed(2)}</h5>
					<FaUsers className="gradient-card-icon" />
					<p className="meta">{t("family.__averageFamilyPerSenior__")}</p>
				</div>
				<br />
				<div className="gradient-card blue">
					<h5 className="gradient-title">{familiesCreatedThisMonth.length}</h5>
					<FaUserPlus className="gradient-card-icon" />
					<p className="meta">{t("family.__accountsCreatedThisMonth__")}</p>
				</div>
			</div>
			<div className="col-md-3">
				<div className="gradient-card blue">
					<h5 className="gradient-title">{averageAffiliatePerPrincipal.toFixed(2)}</h5>
					<FaUsers className="gradient-card-icon" />
					<p className="meta">{t("family.__averageAffiliatesPerPrincipal__")}</p>
				</div>
				<br />
				<div className="gradient-card blue">
					<h5 className="gradient-title">{familiesCreatedThisYear.length}</h5>
					<FaUserPlus className="gradient-card-icon" />
					<p className="meta">{t("family.__accountsCreatedThisYear__")}</p>
				</div>
			</div>
			<div className="col-md-6 ">
				<PieChartContainer data={data} dataKey="value" height={200} showPercentage={false} />
			</div>
			<div className="col-6 mt-2">
				{lastFamiliesConnected && lastFamiliesConnected.length > 0 ? (
					<div className="header-details">
						<h5>{t("common.__lastConnected__")}</h5>
						{lastFamiliesConnected.map((family: FamilyInterface) => (
							<div key={family.id} className="light-table">
								<p>
									{family.last_name.toUpperCase()} {family.first_name}
								</p>
								<p className="meta">{family.last_sign_in_at ? handleDate(family.last_sign_in_at, "Pp", userLocale) : null}</p>
							</div>
						))}
					</div>
				) : null}
			</div>
			<div className="col-6 mt-2">
				{activeMembers && activeMembers.length > 0 ? (
					<div className="header-details">
						<h5>{t("common.__activeMembers__")}</h5>
						{activeMembers.map((family: FamilyInterface) => (
							<div key={family.id} className="light-table">
								<p>
									{family.last_name.toUpperCase()} {family.first_name}
								</p>
								<p className="meta">{family.sign_in_count}</p>
							</div>
						))}
					</div>
				) : null}
			</div>
		</div>
	);
};
export default FamilyStatistics;
