import React, { useEffect, useReducer } from "react";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import ChangeStatistics from "../components/DiaperConnected/ChangeStatistics/ChangeStatistics";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

export interface InitialStateProps {
	loading: boolean;
	startDate: Date;
	endDate: Date;
	startTime: Date;
	endTime: Date;
	selectedSenior: SeniorInterface | null;
	error: boolean;
	data: any | null;
	filter: string;
	tableData: [] | null;
	filteredTableData: [] | null;
	selectedFilter: [] | string | null;
}

export const DiaperChangeReducer = (state: InitialStateProps, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true
			};
		case "START_DATE":
			return {
				...state,
				startDate: action.payload
			};
		case "END_DATE":
			return {
				...state,
				endDate: action.payload
			};
		case "START_TIME":
			return {
				...state,
				startTime: action.payload
			};
		case "END_TIME":
			return {
				...state,
				endTime: action.payload
			};
		case "SET_SENIOR":
			return {
				...state,
				selectedSenior: action.payload
			};
		case "GET_DATA":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case "TABLE_DATA":
			return {
				...state,
				tableData: action.payload,
				loading: false
			};

		case "FILTERED_TABLE_DATA": {
			const initialResult = state.tableData ? state.tableData : [];
			const selectedFilters = action.payload;

			const filteredTableData = initialResult.map((item: { [x: string]: any }) => {
				const newItem: { [key: string]: any } = {};
				Object.entries(item).forEach(([key, value]) => {
					if (selectedFilters.includes(key) && (value === 1 || value === 2)) {
						newItem[key] = value;
					}
				});

				if (Object.keys(newItem).length !== 0) {
					Object.entries(item).forEach(([key, value]) => {
						if (key === "senior_id" || key === "diaper_name"|| key === "old_diaper_name" || key === "date") {
							newItem[key] = value;
						}
					});
				}

				return newItem;
			});

			return {
				...state,
				filteredTableData: filteredTableData,
				filter: selectedFilters
			};
		}
		case "RESET_TABLE_DATA":
			return {
				...state,
				filter: "",
				filteredTableData: null
			};

		default:
			return state;
	}
};

export interface ChangeTableProps {
	senior_id: number;
	old_diaper_name: string;
	diaper_name: string;
	date: Date;
	dirty_bed: boolean;
	diaper_saturated: boolean;
	presence_of_stools: boolean;
	presence_of_redness: boolean;
	torn_off: boolean;
	draw_sheet_changed: boolean;
	bring_to_wc: boolean;
}

const DiaperChangeStatistics = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const seniorsFromHome = store.seniors.seniorsFromHome;

	let selectedSenior: SeniorInterface | null;
	try {
		const item = localStorage.getItem("selectedSenior");
		selectedSenior = item ? JSON.parse(item) : null;
	} catch (error) {
		selectedSenior = null;
	}

	const matchingChangeSensor = seniorsFromHome.filter((senior: { value: any }) => {
		const name = senior.value;
		const selectedName = selectedSenior && selectedSenior.value ? selectedSenior.value : null;
		return name === selectedName;
	});

	const initialState = {
		startDate: new Date(
			localStorage.getItem("startDate") || new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
		),
		endDate: new Date(localStorage.getItem("endDate") || new Date() || ""),
		startTime: new Date(localStorage.getItem("startTime") || new Date() || ""),
		endTime: new Date(localStorage.getItem("endTime") || new Date() || ""),
		selectedSenior: matchingChangeSensor.length > 0 ? matchingChangeSensor[0] : null,
		loading: false,
		error: false,
		data: null,
		tableData: null,
		filter: "",
		filteredTableData: null,
		selectedFilters: []
	};

	const [changeStatisticStore, changeStatisticDispatch] = useReducer(DiaperChangeReducer, initialState);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			localStorage.setItem("startDate", changeStatisticStore.startDate.toISOString());
			localStorage.setItem("endDate", changeStatisticStore.endDate.toISOString());
			localStorage.setItem("startTime", changeStatisticStore.startTime.toISOString());
			localStorage.setItem("endTime", changeStatisticStore.endTime.toISOString());
		}
		return function cleanup() {
			mounted = false;
		};
	}, [
		changeStatisticStore.startDate,
		changeStatisticStore.endDate,
		changeStatisticStore.startTime,
		changeStatisticStore.endTime,
		changeStatisticStore.selectedSenior
	]);

	useEffect(() => {
		let mounted = true;

		if (homeId && isDiaperManager && mounted) {
			changeStatisticDispatch({ type: "LOADING" });
			const startDateFormatted = handleDate(changeStatisticStore.startDate, "yyyy-MM-dd");
			const endDateFormatted = handleDate(changeStatisticStore.endDate, "yyyy-MM-dd");
			const startTimeFormatted = handleDate(changeStatisticStore.startTime, "HH:mm:ss");
			const endTimeFormatted = handleDate(changeStatisticStore.endTime, "HH:mm:ss");

			const data = changeStatisticStore.selectedSenior
				? {
						from: `${startDateFormatted} ${startTimeFormatted}`,
						to: `${endDateFormatted} ${endTimeFormatted}`,
						senior_id: changeStatisticStore.selectedSenior.id
				  }
				: {
						from: `${startDateFormatted} ${startTimeFormatted}`,
						to: `${endDateFormatted} ${endTimeFormatted}`,
						home_id: homeId
				  };

			http
				.post(`${url.BASEURL}/rht/report/change_evals`, data)
				.then(res => changeStatisticDispatch({ type: "GET_DATA", payload: res.data }))
				.catch(() => changeStatisticDispatch({ type: "GET_DATA", payload: null }));

			http
				.post(`${url.BASEURL}/rht/report/dirty_bed/list`, data)
				.then(res => {
					const tableData: ChangeTableProps[] = res.data.map((item: any) => {
						return {
							senior_id: item.senior_id,
							old_diaper_name: item.old_diaper_name,
							diaper_name: item.diaper_name,
							date: item.date,
							dirty_bed: item.dirty_bed,
							diaper_saturated: item.diaper_saturated,
							presence_of_stools: item.presence_of_stools,
							presence_of_redness: item.presence_of_redness,
							torn_off: item.torn_off,
							draw_sheet_changed: item.draw_sheet_changed,
							bring_to_wc: item.bring_to_wc
						};
					});
					changeStatisticDispatch({ type: "TABLE_DATA", payload: tableData });
				})
				.catch(error => {
					changeStatisticDispatch({ type: "TABLE_DATA", payload: null });
				});
		}

		return function cleanup() {
			mounted = false;
		};
	}, [
		homeId,
		isDiaperManager,
		changeStatisticStore.startDate,
		changeStatisticStore.endDate,
		changeStatisticStore.startTime,
		changeStatisticStore.endTime,
		changeStatisticStore.selectedSenior
	]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<ChangeStatistics
							userState={userState}
							homeId={homeId}
							seniorsFromHome={seniorsFromHome}
							changeStatisticStore={changeStatisticStore}
							changeStatisticDispatch={changeStatisticDispatch}
						/>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperChangeStatistics;
